import React from "react";
import { useState } from "react";
import "./nav.css";
import { Link } from "react-router-dom";

function Nav() {
    const [highlightIndex, setHighlightIndex] = useState(2);

    return (
        <div className="nav">
            <div className="logo">
                {" "}
                <Link
                    to="/"
                    onClick={() => {
                        setHighlightIndex(0);
                    }}
                >
                    <div className="logoContainer">
                        <div className="logoCircle"></div>
                        <div className="logoText"></div>
                    </div>
                </Link>
            </div>
            <div className="navLinks">
                <Link
                    onClick={() => {
                        setHighlightIndex(0);
                    }}
                    to={"/"}
                >
                    Acasa
                </Link>
                <Link
                    onClick={() => {
                        setHighlightIndex(1);
                    }}
                    to={"/despre"}
                >
                    Despre noi
                </Link>
                <Link
                    onClick={() => {
                        setHighlightIndex(2);
                    }}
                    to={"/utilaje"}
                >
                    Utilaje
                </Link>
                <Link
                    onClick={() => {
                        setHighlightIndex(3);
                    }}
                    to={"/servicii"}
                >
                    Servicii
                </Link>
                <Link
                    onClick={() => {
                        setHighlightIndex(4);
                    }}
                    to={"/portofoliu"}
                >
                    Portofoliu
                </Link>
                <Link
                    onClick={() => {
                        setHighlightIndex(5);
                    }}
                    to={"/contact"}
                >
                    Contact
                </Link>
                <div className="linkHighlight">
                    <div className="highlight" style={{ left: `calc(100%/6 * ${highlightIndex})` }}></div>
                </div>
            </div>
        </div>
    );
}

export default Nav;

/* <Link to={"/"}>Acasa</Link>
                <Link to={"/concursuri"}>Concursuri</Link> */
