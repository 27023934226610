import React, { useState } from "react";
import "./mobileNav.css";
import { Link } from "react-router-dom";

function MobileNav() {
    const [toggled, setToggled] = useState(false);
    const [currentPage, setCurrentPage] = useState("Acasa");

    return (
        <div
            className="mobileNav"
            style={{
                height: toggled ? "100svh" : "4rem",
                background: toggled ? "rgba(13, 13, 41, 1)" : "rgba(13, 13, 41, 0)",
            }}
        >
            <div
                className="navHeader"
                onClick={() => {
                    setToggled(!toggled);
                }}
            >
                <img src="./media/ui/logo.svg" />
                <h2>{currentPage}</h2>
                <div className="toggleBtn">
                    <div className="line" style={{ rotate: toggled ? "0deg" : "180deg" }}></div>
                    <div className="line" style={{ rotate: toggled ? "0deg" : "90deg" }}></div>
                </div>
            </div>
            {toggled ? (
                <div
                    className="navContent"
                    onClick={() => {
                        setToggled(false);
                    }}
                >
                    <Link
                        to="/"
                        onClick={() => {
                            setCurrentPage("Acasa");
                        }}
                    >
                        Acasa
                    </Link>
                    <Link
                        to="/despre"
                        onClick={() => {
                            setCurrentPage("Despre noi");
                        }}
                    >
                        Despre noi
                    </Link>
                    <Link
                        to="/utilaje"
                        onClick={() => {
                            setCurrentPage("Utilaje");
                        }}
                    >
                        Utilaje
                    </Link>
                    <Link
                        to="/servicii"
                        onClick={() => {
                            setCurrentPage("Servicii");
                        }}
                    >
                        Servicii
                    </Link>
                    <Link
                        to="/portofoliu"
                        onClick={() => {
                            setCurrentPage("Portofoliu");
                        }}
                    >
                        Portofoliu
                    </Link>
                    <Link
                        to="/contact"
                        onClick={() => {
                            setCurrentPage("Contact");
                        }}
                    >
                        Contact
                    </Link>
                </div>
            ) : null}
        </div>
    );
}

export default MobileNav;
