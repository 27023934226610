import React, { useEffect, useState } from "react";
import "./hero.css";
import { useContext } from "react";
import WidthContext from "../../context/WidthContext";
import Nav from "../nav/Nav";
import MobileNav from "../nav/MobileNav";

function Hero() {
    const width = useContext(WidthContext);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setImageIndex(imageIndex === 3 ? 0 : imageIndex + 1);
        }, 4000);
    }, [imageIndex]);

    return (
        <div className="hero">
            <div className="heroNav">{width > 600 ? <Nav /> : <MobileNav />}</div>
            {imageIndex === 0 ? (
                <>
                    <img className="heroImage" src={`./media/hero/0.png`} alt="" />
                    <p className="heroText">{heroTextArray[0]}</p>
                </>
            ) : null}
            {imageIndex === 1 ? (
                <>
                    <img className="heroImage" src={`./media/hero/1.png`} alt="" />
                    <p className="heroText">{heroTextArray[1]}</p>
                </>
            ) : null}
            {imageIndex === 2 ? (
                <>
                    <img className="heroImage" src={`./media/hero/2.png`} alt="" />
                    <p className="heroText">{heroTextArray[2]}</p>
                </>
            ) : null}
            {imageIndex === 3 ? (
                <>
                    <img className="heroImage" src={`./media/hero/3.png`} alt="" />
                    <p className="heroText">{heroTextArray[3]}</p>
                </>
            ) : null}
        </div>
    );
}

export default Hero;

const heroTextArray = [
    "Lucrări de instalații electrice tip C2A pentru executare de linii electrice, aeriene sau subterane, cu tensiuni nominale de 0,4KW - 20KW, posturi de transformare cu tensiunea nominală superioară de cel mult 20KW, stații de medie tensiune, precum și partea electrică de medie tensiune a stațiilor de înaltă tensiune. ",
    "Lucrări de instalații electrice tip Bp, pentru proiectare de instalații electrice exterioare/interioare, pentru incinte/construcții civile și industriale, branșamente aeriene și subterane la tensiunea nominala de 0.4KW",
    "Servicii de închiriere utilaje.",
    "Iluminat public autorizat A.N.R.S.C.",
];
