import React from "react";
import "./acasa.css";
import "../page.css";

function Acasa() {
    return (
        <div className="acasa page">
            <h1 className="sectionTitle">De ce să apelezi la Instalco AG pentru instalaţiile tale electrice?</h1>
            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Instalco AG se menţine una dintre cele mai de succes firme de instalaţii electrice din judeţul Constanţa, care prin toate
                        proiectele realizate, asigură clienţilor calitatea şi rezistenţa instalaţiilor proiectate. Profesionalismul, dedicarea şi
                        implicarea în fiecare proiect în parte sunt câteva dintre motivele pentru care oricine ar trebui să apeleze la noi.
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/7.png" />
                </div>
            </div>
            <br />
            <h1 className="sectionTitle">Orice imobil are nevoie de instalaţii electrice de calitate</h1>
            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Sub de deviza “Niciodată rabat de la calitate”, firma noastră de instalaţii electrice, Instalco AG, asigură montarea de
                        instalaţii electrice indispensabile oricărei clădiri, indiferent că este vorba despre o casă, fie că serviciile sunt
                        solicitate în cazul unui complex comercial cu o întindere mare.
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/8.png" />
                </div>
            </div>
        </div>
    );
}

export default Acasa;
