import React from "react";
import "./despre.css";
import "../page.css";

function Despre() {
    return (
        <div className="despre page">
            <h1 className="sectionTitle">Despre noi</h1>
            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Firma noastră de instalaţii electrice din Mangalia deţine o experienţă de 26 de ani în domeniul construcţiilor şi
                        instalaţiilor, fiind unul dintre numele de referinţă pe piaţa din judeţul Constanţa.
                    </p>
                    <p>
                        Firma de instalaţii electrice a fost înfiinţată în 1994 şi a pornit de la ideea unui proiect îndrăzneţ, cu o viziune altfel
                        faţă de competitorii săi.
                    </p>
                    <p>
                        Astfel, principalul scop al firmei de instalaţii electrice a fost oferirea de servicii de proiectare şi construire de clărdiri
                        la “cheie”, adică construirea lor, finisarea, montarea de instalaţii termice şi sanitare, electrice şi amenajarea spaţiilor
                        exterioare.
                    </p>
                    <p>
                        Pasiunea pe care o pune în fiecare proiect, precum şi profesionalismul sunt câteva dintre motivele pentru care oricne ar
                        trebui să apeleze la serviciile de cea mai bună calitate, puse la dispoziţie de Instalco AG.
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/1.png" />
                </div>
            </div>

            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Prin managementul eficient şi reducerea costurilor de personal, putem oferi servicii la cel mai înalt standard tehnic,
                        flexibilitate, fiabilitate, calitate, în conformitate cu legislaţia în vigoare şi conform cu autorizările ANRE şi ISO
                        9001-2015 sub care ne desfăşurăm activitatea.
                    </p>
                    <p>
                        Din anul 1994, suntem prezenţi şi pe piaţa internaţională, furnizând personal calificat şi bine pregătit, în special pentru
                        construcţii industriale şi construcţii civile (construcţii noi şi reparaţii)
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/3.png" />
                </div>
            </div>
        </div>
    );
}

export default Despre;
