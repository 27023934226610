import React from "react";
import "./portofoliu.css";
import "../page.css";

function Portofoliu() {
    return (
        <div className="portofoliu page">
            <h1 className="sectionTitle">Portofoliu</h1>
            <ul>
                <li>RAJA</li>
                <li>Moussay</li>
                <li>Galileo</li>
                <li>Primăria Mangalia</li>
                <li>Autoprima</li>
                <li>Primăria Cumpăna</li>
                <li>Terasă / Bar - EXPIRAT (Vama Veche)</li>
                <li>Hală – Crescătorie păsări (Sibioara) instalaţie interioară</li>
                <li>Magazin alimentar – PROFI (Mangalia)</li>
                <li>Bloc locuinţe – PESCĂRUŞ (Mangalia)</li>
                <li>Bloc locuinţe – DIA LITORAL (Mangalia)</li>
                <li>Restaurant – La Canapele (Vama Veche)</li>
                <li>Complex – CIREŞICA (Neptun-Olimp)</li>
                <li>Ansamblu locuinţe (Cumpăna)</li>
                <li>Restaurant / Terasă – LA PUIU (Mangalia)</li>
                <li>Hotel VIOLETA (Jupiter)</li>
                <li>Complex turistic SANDALANDALA (Vama Veche)</li>
                <li>Sanatoriul Balnear Techirghiol</li>
                <li>Patriarhia Ortodoxă Constanţa</li>
                <li>Venul Holiday 2000 SA (Vile Venus)</li>
                <li>Sala Sport / Şcoala Pecineaga</li>
                <li>Iluminat stradal de sărbători Pecineaga, Cumpăna</li>
                <li>Iluminat public Pecineaga, Cumpăna</li>
                <li>Întărire reţea Com. Limanu, sat Hagieni</li>
                <li>Power Area Construct SRL instalaţie interioară</li>
            </ul>
        </div>
    );
}

export default Portofoliu;
