import React from "react";
import "./gallery.css";

function Gallery({ children }) {
    return (
        <div className="gallery">
            {children.map(img => (
                <GalleryCard image={img} />
            ))}
        </div>
    );
}

export default Gallery;

function GalleryCard({ image }) {
    return <div className="galleryCard">{image}</div>;
}
