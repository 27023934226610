import React from "react";
import "./servicii.css";
import "../page.css";
import Gallery from "../../components/gallery/Gallery";

function Servicii() {
    return (
        <div className="servicii page">
            <h1 className="sectionTitle">Instalații electrice</h1>
            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Fiindcă este una dintre firmele de instalaţii electrice din judeţul Constanţa cu notorietate, Instalco AG are grijă ca prin
                        serviciile asigurate de profesioniştii săi în domeniul instalaţiilor electrice, clienţii să beneficieze de sisteme electrice
                        sigure şi montate corespunzător.
                    </p>
                    <p>
                        De-a lungul timpului, Instalco AG şi-a îmbogăţit gama de servicii de instalaţii electrice, astfel încât să acopere o arie cât
                        mai largă de solicitări.
                    </p>
                    <p>
                        Deşi am pornit de la proiecte de instalaţii electrice de o mică dificultate, am ajuns să fim una dintre cele mai de încredere
                        firme de instalaţii electrice din judeţul Constanţa şi, an de an, să ne îmbogăţim oferta de servicii.
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/autorizatie.png" alt="" />
                </div>
            </div>
            <h1 className="sectionTitle">Servicii</h1>
            <ul>
                <li>Iluminat public autorizat A.N.R.S.C.</li>
                <li>Modernizări de cazane industriale (abur şi apă fierbinte)</li>
                <li>Sisteme centralizate pentru urmărirea consumurilor energetice (energie electrică și utilități)</li>
                <li>Automatizări generale (compresoare, prese, acționari electrice)</li>
                <li>Automatizări de flixuri tehnologice</li>
                <li>Aplicații pentru linii de producție, montaj, vopsitorie etc.</li>
                <li>Proiectarea și execuţia instalaţiilor electrice cu tensiuni până la 1000 V</li>
                <li>
                    Instalarea și punerea în funcţiune a mașinilor și utilajelor electrice, dar şi efectuarea de revizii, întreţinere și reparaţii
                </li>
                <li>Efectuarea de măsurători şi verificări a stării tehnice a instalaţiilor electrice</li>
                <li>Consultanţă în reabilitarea și modernizarea instalaţiilor electrice de utilzare</li>
                <li>Intervenții electrice autorizate, constatarea avariilor de natură electrică</li>
                <li>
                    Montaj corpuri de iluminat (lustre, lămpi, plafoniere, aplice, neoane, proiectoare, leduri, surse led, instalații electrice
                    interioare)
                </li>
                <li>
                    Reparații instalații electrice interioare, construcții civile, garsoniere, apartamente, case, vile, sedii firme, înlocuire
                    circuite de iluminat, prize, întrerupătoare, comutatoare
                </li>
                <li>Montaj autorizat paratrăsnet, prize împământare</li>
                <li>Montaj, înlocuire tablouri electrice, siguranțe, suporți pentru contori</li>
                <li>Înlocuire instalație de iluminat de siguranță 24v</li>
                <li>Branșamente electrice pentru persoane fizice și juridice</li>
                <li>Proiecte pentru instalații electrice interioare</li>
                <li>Construcții civile și industriale (instalații sanitare și instalații termice)</li>
                <li>Instalații de alimentare cu energie electrică a diverșilor consumatori</li>
                <li>Branşamente casmice, branşamente apa şi canalizare</li>
            </ul>
            <br />
            <h1 className="sectionTitle">Proiectare instalaţii electrice</h1>
            <div className="text-imageCard">
                <div className="textBlock">
                    <p>
                        Oferim soluţii tehnice pentru toate tipurile de imobile, case, vile, blocuri de locuinţe, spaţii comerciale, fabrici.
                        Proiectele de instalaţii electrice realizate la sediul firmei noastre au în vedere cei mai importanţi parametri, cum ar fi
                        calculul luminatului,, palnurile de amplasare a aparatelor electrice, schema tablourilor electrice.
                    </p>
                    <p>
                        În cazul proiectării de instalaţii electrice exterioare, efectuăm calculul pentru priza de pământ, iluminatul exterior şi
                        calculul paratrăsnetului. Oferim consultanţă şi proiectare pentru sisteme de iluminat interior/exterior, instalaţii de
                        avertizare sau semnalizare, sisteme anti efracţie, memoriu tehnic, caiet de sarcini şi antemăsurători.
                    </p>
                    <p>
                        Dacă pentru instalaţiile electrice civile, complexitatea curenţilor slabi este relativ constantă, o atenţie suplimentară
                        necesită instalaţiile electrice industriale, în cazul cărora capacitatea cerută de iluminare şi forţa poate varia extrem de
                        mult. Necesităţi sporite de curent în domeniul industrial nu înseamnă neapărat consumuri excesive dacă distribuirea
                        instalaţiei este bine gândită din punct de vedere economic. Instalco AG lucrează în avantajul clienţilor, astfel ne angajăm să
                        găsim cea mai bună variantă pentru o clădire scutită de scurtcircuite.
                    </p>
                    <p>
                        Tarifele pentru proiectarea instalaţiilor se stabilesc per mp de construcţie raportat la destinaţia, mărimea imobilului şi
                        cerinţele impuse.
                    </p>
                    <p>
                        Menţionăm că suntem autorizaţi IGSU - proiectant sisteme de securitate la incendiu (detecţie incendiu) – conform cod COR
                        215119.
                    </p>
                </div>
                <div className="imgBlock">
                    <img src="./media/photos/2.png" alt="" />
                </div>
            </div>
            <Gallery>
                <img src="./media/photos/21.jpg" alt="" />
                <img src="./media/photos/22.jpg" alt="" />
                <img src="./media/photos/23.jpg" alt="" />
                <img src="./media/photos/24.jpg" alt="" />
                <img src="./media/photos/25.jpg" alt="" />
                <img src="./media/photos/26.jpg" alt="" />
                <img src="./media/photos/27.jpg" alt="" />
                <img src="./media/photos/28.jpg" alt="" />
                <img src="./media/photos/29.jpg" alt="" />
            </Gallery>
        </div>
    );
}

export default Servicii;
