import { Routes, Route } from "react-router-dom";
import Acasa from "./pages/acasa/Acasa";
import Contact from "./pages/contact/Contact";
import Despre from "./pages/despre/Despre";
import Portofoliu from "./pages/portofoliu/Portofoliu";
import Servicii from "./pages/servicii/Servicii";
import Utilaje from "./pages/utilaje/Utilaje";
import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import MobileNav from "./components/nav/MobileNav";
import Hero from "./components/hero/Hero";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";
import WidthContext from "./context/WidthContext";
import "./index.css";

function App() {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <WidthContext.Provider value={width}>
            <div className="app">
                <header>
                    <Hero />
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<Acasa />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/despre" element={<Despre />} />
                        <Route path="/portofoliu" element={<Portofoliu />} />
                        <Route path="/servicii" element={<Servicii />} />
                        <Route path="/utilaje" element={<Utilaje />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </WidthContext.Provider>
    );
}

export default App;
