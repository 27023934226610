import React from "react";
import "./contact.css";
import Map from "../../components/map/Map";
import "../page.css";

function Contact() {
    return (
        <div className="contact page">
            <h1 className="sectionTitle">Ne puteți contacta la:</h1>
            <div className="contactCard">
                <div className="contactInfo">
                    <img src="./media/photos/6.png" alt="" />
                    <ContactInfo />
                </div>
                <Map />
            </div>
        </div>
    );
}

export default Contact;

function ContactInfo() {
    return (
        <div className="infoLines">
            <div className="infoLine">
                <img src="./media/ui/locationIcon.svg" alt="" />
                <h2>
                    Sediul Central: <span className="sans">Magngalia, Str. Rozelor Nr. 1</span>{" "}
                </h2>
            </div>
            <div className="infoLine">
                <img src="./media/ui/phoneIcon.svg" alt="" />
                <h2>
                    Telefon:<span className="sans">(+4)0729.09.10.73</span>{" "}
                </h2>
            </div>
            <div className="infoLine">
                <img src="./media/ui/phoneIcon.svg" alt="" />
                <h2>
                    Telefon:<span className="sans">(+4)0742.27.88.11</span>{" "}
                </h2>
            </div>
            <div className="infoLine">
                <img src="./media/ui/emailIcon.svg" alt="" />
                <h2>
                    Email:<span className="sans">instalcoa@yahoo.com</span>{" "}
                </h2>
            </div>
        </div>
    );
}
