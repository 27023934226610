import React from "react";
import "./map.css";
function Map() {
    return (
        <div className="map">
            <iframe
                title="map"
                width="100%"
                height="100%"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?q=SC instalco ag&t=&z=16&ie=UTF8&iwloc=&output=embed"
            ></iframe>
        </div>
    );
}

export default Map;
