import React from "react";
import "./utilaje.css";
import "../page.css";
import Gallery from "../../components/gallery/Gallery";

function Utilaje() {
    return (
        <div className="utilaje page">
            <h1 className="sectionTitle">Oferim servicii de închiriere utilaje</h1>
            <img className="utilajeBanner" src="./media/photos/4.png" alt="" />
            <ul>
                {utilaje.map(({ utilaj, nr }) => {
                    return (
                        <li className="utilaj" key={utilaj}>
                            <span>{utilaj}</span>
                            <span>{nr} buc</span>
                        </li>
                    );
                })}
            </ul>
            <Gallery>
                <img src="./media/photos/9.jpeg" alt="" />
                <img src="./media/photos/10.jpeg" alt="" />
                <img src="./media/photos/11.jpeg" alt="" />
                <img src="./media/photos/12.jpeg" alt="" />
                <img src="./media/photos/13.jpeg" alt="" />
                <img src="./media/photos/14.jpeg" alt="" />
                <img src="./media/photos/15.jpeg" alt="" />
                <img src="./media/photos/16.jpeg" alt="" />
                <img src="./media/photos/17.jpeg" alt="" />
                <img src="./media/photos/18.jpeg" alt="" />
                <img src="./media/photos/19.jpeg" alt="" />
                <img src="./media/photos/20.jpeg" alt="" />
            </Gallery>
        </div>
    );
}

export default Utilaje;

const utilaje = [
    {
        utilaj: "Buldoexcavator",
        nr: 2,
    },
    {
        utilaj: "Miniexcavator 1800 kg",
        nr: 3,
    },
    {
        utilaj: "Miniexavator 4 t",
        nr: 1,
    },
    {
        utilaj: "Maşină tăiat asfalt",
        nr: 2,
    },
    {
        utilaj: "Compactor",
        nr: 3,
    },
    {
        utilaj: "Aparat electrofuziune",
        nr: 2,
    },
    {
        utilaj: "Generator",
        nr: 3,
    },
    {
        utilaj: "Nacelă",
        nr: 2,
    },
    {
        utilaj: "Autoutilitare",
        nr: 3,
    },
    {
        utilaj: "Bormaşini",
        nr: 3,
    },
    {
        utilaj: "Rotopercutor",
        nr: 2,
    },
    {
        utilaj: "Auto 7 t",
        nr: 1,
    },
];
